import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Row, Col, FormGroup } from "reactstrap";

const MultiSelect = ({ options, maxSelection, onChange, minSelection }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = React.useState(false);

  const propertyTimeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#114253",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionToggle = (option) => {
    if (selectedOptions.includes(option)) {
      const userSelectedOptions = selectedOptions.filter(
        (item) => item !== option
      );
      setSelectedOptions(userSelectedOptions);
      onChange(userSelectedOptions);
    } else {
      if (selectedOptions.length < maxSelection) {
        setSelectedOptions([...selectedOptions, option]);
        onChange([...selectedOptions, option]);
      } else {
        // Alert or some indication of maximum selection reached
        handleDialogOpen();
      }
    }
  };

  //   return (
  //     <div>
  //       {options.map((option) => (
  //         <div key={option}>
  //           <input
  //             type="checkbox"
  //             id={option}
  //             checked={selectedOptions.includes(option)}
  //             onChange={() => handleOptionToggle(option)}
  //           />
  //           <label htmlFor={option}>{option}</label>
  //         </div>
  //       ))}
  //     </div>
  //   );
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-options-alert-dialog-title"
        aria-describedby="max-options-alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="max-options-alert-dialog-description">
            {`You can only select up to ${maxSelection} options.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Row>
        <Col md="12">
          <FormGroup>
            <Grid
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              {options.map((option) => (
                <Grid
                  sx={{ flexDirection: "column", padding: "4px" }}
                  key={option}
                >
                  <Box
                    onClick={() => handleOptionToggle(option)}
                    key={option}
                    sx={propertyTimeBoxSx}
                    style={{
                      backgroundColor: selectedOptions.includes(option)
                        ? "#e61a4b"
                        : "#114253",
                    }}
                  >
                    {option}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default MultiSelect;
