import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Moment from "moment";
import "moment/locale/en-gb";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";

import FamilyMemberService from "services/family-member.service";
import EmploymentService from "services/employment.service";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";

const EmploymentAdd = () => {
  const [requiredState, setrequiredState] = useState("");
  const employmentTypes = [
    "Full time",
    "Part time",
    "Self employed",
    "Director",
    "Partner",
    "Retired",
  ];

  let yellow = "#fbc658";
  const [selectedFamilyMemberId, setSelectedFamilyMemberId] = useState({});
  const [occupationTitle, setOccupationTitle] = useState("");
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [stillWorkHere, setStillWorkHere] = useState(false);
  const [jobSecurityRating, setJobSecurityRating] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [open, setOpen] = useState(false);
  const [employmentId, setEmploymentId] = useState(0);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState("");
  const [familyMemberNames, setFamilyMemberNames] = useState([]);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [selectedClientstate, setSelectedClientState] =
    useAtom(selectedClientAtom);

  const [validationErrorsSavingDetails, setValidationErrorsSavingDetails] =
    useState({});

  // Saving Detail Form Validation
  function validateFormFamilyDetails() {
    console.log("familily Member Name", selectedFamilyMemberId.value);
    const errorsSavingDetails = {};
    if (!selectedFamilyMemberId.value)
      errorsSavingDetails.familyMember = "* Select Family Member"; //"Family member is required";
    setValidationErrorsSavingDetails(errorsSavingDetails);
    return Object.keys(errorsSavingDetails).length === 0;
  }

  // const [familyMemberId, setFamilyMemberId] = useState(0);

  const history = useHistory();

  const getEmploymentId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };

  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length < 5
      ? false
      : true;

  console.log("isupdate: ", isUpdate);

  useEffect(() => {
    if (isUpdate) {
      getEmploymentById();
    } else {
      getFamilyMemberNamesByClientId(clientState?.clientId);
    }
  }, [employmentId, clientState?.clientId]);

  const getFamilyMemberNamesByClientId = async (id) => {
    FamilyMemberService.getFamilyMemberNamesByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const names = response.data.recordset;
          console.log(names);
          let optionItems = names.map((item) => ({
            label: `${item.FirstName} ${item.LastName}`,
            value: item.FamilyMemberId,
          }));
          setFamilyMemberNames(optionItems);
        }
      })
      .catch((error) => {
        console.log(error);
        setFamilyMemberNames([]);
      });
  };

  const getEmploymentById = async () => {
    const employmentId = getEmploymentId();
    EmploymentService.getByEmploymentId(employmentId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const employment = response.data.recordset[0];
          console.log("employment: ", employment);
          setEmployment(employment);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setEmployment = (employment) => {
    const familyMemberName = `${employment.FirstName} ${employment.LastName}`;
    setSelectedFamilyMember(familyMemberName);
    setEmploymentId(employment.EmploymentId);
    setSelectedFamilyMemberId(employment.FamilyMemberId);
    setOccupationTitle(employment.OccupationTitle);
    setEmploymentType(employment.EmploymentType);
    setStartDate(setDateForDisplay(employment.StartDate));
    setStillWorkHere(employment.StillWorkHere);
    setJobSecurityRating(employment.JobSecurityRating);
  };

  const employmentTypeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setEmploymentType = (et) => {
    setSelectedEmploymentType(et);
  };

  const handleDateChange = (e) => {
    Moment.locale("en-au");
    const selectedDate = Moment(e).format("DD/MM/yyyy");
    setStartDate(selectedDate);
  };

  const handleJobSecurityChange = (e) => {
    setJobSecurityRating(e.target.value);
  };

  const handleStillWorkHere = () => {
    setStillWorkHere(!stillWorkHere);
  };

  const setDateForDisplay = (selectedDate) => {
    return selectedDate.slice(0, 10).split("-").reverse().join("/");
  };

  const setDateForInsert = (selectedDate) => {
    return selectedDate.split("/").reverse().join("-");
  };

  const handleFamilyMemberChange = (e) => {
    setSelectedFamilyMemberId(e);
  };

  const redirectToEmployment = (e) => {
    const path = `/admin/scoreboard/employment/list`;
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const handleSave = () => {
    // if (!isUpdate) {
    // }
    addEmployment();
  };

  const addEmployment = () => {
    const newEmployment = {
      familyMemberId: selectedFamilyMemberId.value,
      occupationTitle,
      employmentType: selectedEmploymentType,
      startDate: setDateForInsert(startDate),
      stillWorkHere: stillWorkHere,
      jobSecurityRating,
      userId: userState.userId,
      clientId: selectedClientstate.clientId,
    };
    if (employmentId === undefined || employmentId === 0) {
      if (!validateFormFamilyDetails()) {
        return;
      }

      EmploymentService.add(newEmployment)
        .then((response) => {
          if (response.data.recordset.length > 0) {
            setEmploymentId(response.data.recordset[0].EmploymentId);
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          } else {
            setPortfolioAnalysisId(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const employment = {
        occupationTitle,
        employmentType: selectedEmploymentType,
        startDate: setDateForInsert(startDate),
        stillWorkHere: stillWorkHere,
        jobSecurityRating,
        userId,
      };
      console.log("employment: ", employment);
      EmploymentService.update(employmentId, employment)
        .then(() => {
          setOpen(true);
          setTimeout(() => {
            redirectToEmployment();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const updateEmployment = () => {

  //     console.log('update employment data: ', employmentData);
  //     if (employmentId === undefined || employmentId === 0) {
  //         EmploymentService.add(employmentData)
  //             .then((response) => {
  //                 if (response.data.recordset.length > 0) {
  //                     setEmploymentId(response.data.recordset[0].EmploymentId);
  //                     setOpen(true);
  //                     redirectToEmployment(e);
  //                 }
  //                 else {
  //                     setPortfolioAnalysisId(0);
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.log(error);
  //             });
  //     }
  //     else {
  //         EmploymentService.update(employmentData, employmentId)
  //             .then(() => {
  //                 setOpen(true);
  //             })
  //             .catch((error) => {
  //                 console.log(error);
  //             });
  //     }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content employment-add">
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Employment is saved."
        action={action}
      ></Snackbar>
      <Container>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Employment</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      {!isUpdate ? (
                        <FormGroup>
                          {validationErrorsSavingDetails.familyMember ? (
                            <span
                              style={{
                                color: "red",
                                fontWeight: 600,
                                padding: "0px 0px 10px 0px",
                              }}
                            >
                              {validationErrorsSavingDetails.familyMember}
                            </span>
                          ) : (
                            <Label>Select Family Member</Label>
                          )}

                          {/* <Label>Select Family Member</Label> */}
                          <Select
                            name=""
                            className="react-select select-option-control"
                            placeholder="Select Family Member"
                            classNamePrefix="react-select"
                            value={selectedFamilyMemberId}
                            onChange={handleFamilyMemberChange}
                            options={familyMemberNames}
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label>Select Family Member</Label>
                          <Input
                            placeholder=""
                            type="text"
                            value={selectedFamilyMember}
                            disabled={true}
                          />
                        </FormGroup>
                      )}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Occupation Title</Label>
                        <Input
                          placeholder=""
                          type="text"
                          value={occupationTitle}
                          onChange={(e) => setOccupationTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="employment-type">
                    <Col md="12" sm="6">
                      <Label>Employment Type</Label>
                      <FormGroup>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {employmentTypes.map((et) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={et}
                            >
                              <Box
                                onClick={() => setEmploymentType(et)}
                                key={et}
                                sx={employmentTypeBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedEmploymentType === et
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {et}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup className={requiredState}>
                        <Label>Start Date</Label>
                        <ReactDatetime
                          locale="en-gb"
                          inputProps={{
                            className: "form-control",
                            placeholder: "",
                          }}
                          value={startDate}
                          onChange={(e) => handleDateChange(e)}
                          timeFormat={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Label md="3">Still work here</Label>
                    <Col md="9">
                      <FormGroup className={requiredState}>
                        <Checkbox
                          checked={stillWorkHere}
                          onChange={handleStillWorkHere}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md="12">
                      <div className="Checkbox padding-class">
                        <FormGroup check className="text-left">
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={stillWorkHere}
                              onChange={handleStillWorkHere}
                            />
                            <span className={`form-check-sign`} />
                            Still work here
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="9" className="slide_input">
                      <FormGroup className={requiredState}>
                        <Label>How would you rate your job security?</Label>
                        <Box className="Slider fg-margin" width={300}>
                          <Slider
                            size="medium"
                            defaultValue={5}
                            value={jobSecurityRating}
                            marks
                            min={0}
                            max={10}
                            // steps={10}
                            valueLabelDisplay="auto"
                            onChange={(e) => handleJobSecurityChange(e)}
                          />
                        </Box>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                  >
                    SAVE
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmploymentAdd;
