import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Button,
  Typography,
  Grid,
  Collapse,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const PropertyFilter = ({ properties, onFilterChange }) => {
  const [filters, setFilters] = useState({
    priceRange: { min: "", max: "" },
    bedrooms: "",
    bathrooms: "",
    state: "",
    propertyStatus: "",
    suburb: "",
  });

  const [activeFilters, setActiveFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // Unique dropdown values
  const states = [...new Set(properties.map((p) => p.state).filter(Boolean))];
  const statuses = [
    ...new Set(properties.map((p) => p.status).filter(Boolean)),
  ];
  const suburbs = [...new Set(properties.map((p) => p.suburb).filter(Boolean))];

  const applyFilters = () => {
    let filteredProperties = [...properties];
    const newActiveFilters = [];

    // Price Range Filter
    if (filters.priceRange.min || filters.priceRange.max) {
      filteredProperties = filteredProperties.filter((property) => {
        const price = property.askingPrice;
        const meetsMinimum =
          !filters.priceRange.min || price >= Number(filters.priceRange.min);
        const meetsMaximum =
          !filters.priceRange.max || price <= Number(filters.priceRange.max);
        return meetsMinimum && meetsMaximum;
      });
      newActiveFilters.push(
        `Price: $${filters.priceRange.min || 0} - $${
          filters.priceRange.max || "∞"
        }`
      );
    }

    // Bedrooms Filter
    if (filters.bedrooms) {
      filteredProperties = filteredProperties.filter(
        (p) => p.noOfBedRooms === Number(filters.bedrooms)
      );
      newActiveFilters.push(`${filters.bedrooms} Bedrooms`);
    }

    // Bathrooms Filter
    if (filters.bathrooms) {
      filteredProperties = filteredProperties.filter(
        (p) => p.noOfBathRooms === Number(filters.bathrooms)
      );
      newActiveFilters.push(`${filters.bathrooms} Bathrooms`);
    }

    // State Filter
    if (filters.state) {
      filteredProperties = filteredProperties.filter(
        (p) => p.state === filters.state
      );
      newActiveFilters.push(`State: ${filters.state}`);
    }

    // Status Filter
    if (filters.propertyStatus) {
      filteredProperties = filteredProperties.filter(
        (p) => p.status === filters.propertyStatus
      );
      newActiveFilters.push(`Status: ${filters.propertyStatus}`);
    }

    // Suburb Filter
    if (filters.suburb) {
      filteredProperties = filteredProperties.filter(
        (p) => p.suburb === filters.suburb
      );
      newActiveFilters.push(`Suburb: ${filters.suburb}`);
    }

    setActiveFilters(newActiveFilters);
    onFilterChange(filteredProperties);
  };

  const clearFilters = () => {
    setFilters({
      priceRange: { min: "", max: "" },
      bedrooms: "",
      bathrooms: "",
      state: "",
      propertyStatus: "",
      suburb: "",
    });
    setActiveFilters([]);
    onFilterChange(properties);
  };

  const removeFilter = (filterText) => {
    const filterType = filterText.split(":")[0].trim().toLowerCase();
    const newFilters = { ...filters };

    switch (filterType) {
      case "price":
        newFilters.priceRange = { min: "", max: "" };
        break;
      case "bedrooms":
        newFilters.bedrooms = "";
        break;
      case "bathrooms":
        newFilters.bathrooms = "";
        break;
      case "state":
        newFilters.state = "";
        break;
      case "status":
        newFilters.propertyStatus = "";
        break;
      case "suburb":
        newFilters.suburb = "";
        break;
    }

    setFilters(newFilters);
    const updatedActiveFilters = activeFilters.filter((f) => f !== filterText);
    setActiveFilters(updatedActiveFilters);
  };

  return (
    <Card className="mb-4">
      <CardContent>
        <Box className="mb-4">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="mb-4"
          >
            {/* <Typography variant="h6">
              <FilterListIcon className="mr-2" /> Property Filters
            </Typography> */}
            <Button
              variant="outlined"
              onClick={() => setShowFilters(!showFilters)}
              startIcon={showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {showFilters ? "Hide Property Filters" : "Show Property Filters"}
            </Button>
          </Box>

          {/* Collapsible Filters Section */}
          <Collapse in={showFilters}>
            <Grid container spacing={2}>
              {/* Price Range Filters */}
              <Grid item xs={12} md={2}>
                <TextField
                  label="Min Price"
                  type="number"
                  value={filters.priceRange.min}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      priceRange: {
                        ...filters.priceRange,
                        min: e.target.value,
                      },
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  label="Max Price"
                  type="number"
                  value={filters.priceRange.max}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      priceRange: {
                        ...filters.priceRange,
                        max: e.target.value,
                      },
                    })
                  }
                  fullWidth
                />
              </Grid>

              {/* Bedrooms Filter */}
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Bedrooms</InputLabel>
                  <Select
                    value={filters.bedrooms}
                    label="Bedrooms"
                    onChange={(e) =>
                      setFilters({ ...filters, bedrooms: e.target.value })
                    }
                  >
                    <MenuItem value="">Any</MenuItem>
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Bathrooms Filter */}
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Bathrooms</InputLabel>
                  <Select
                    value={filters.bathrooms}
                    label="Bathrooms"
                    onChange={(e) =>
                      setFilters({ ...filters, bathrooms: e.target.value })
                    }
                  >
                    <MenuItem value="">Any</MenuItem>
                    {[1, 2, 3, 4].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* State Filter */}
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={filters.state}
                    label="State"
                    onChange={(e) =>
                      setFilters({ ...filters, state: e.target.value })
                    }
                  >
                    <MenuItem value="">Any</MenuItem>
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Status Filter */}
              {/* <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={filters.propertyStatus}
                    label="Status"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        propertyStatus: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="">Any</MenuItem>
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* Suburb Filter */}
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Suburb</InputLabel>
                  <Select
                    value={filters.suburb}
                    label="Suburb"
                    onChange={(e) =>
                      setFilters({ ...filters, suburb: e.target.value })
                    }
                  >
                    <MenuItem value="">Any</MenuItem>
                    {suburbs.map((suburb) => (
                      <MenuItem key={suburb} value={suburb}>
                        {suburb}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Filter Actions */}
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applyFilters}
                  startIcon={<FilterListIcon />}
                  fullWidth
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  onClick={clearFilters}
                  startIcon={<CloseIcon />}
                  fullWidth
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Collapse>

          {/* Active Filters */}
          {activeFilters.length > 0 && (
            <Box className="mt-4">
              <Typography variant="subtitle2" className="mb-2">
                Active Filters:
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {activeFilters.map((filter, index) => (
                  <Chip
                    key={index}
                    label={filter}
                    onDelete={() => removeFilter(filter)}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PropertyFilter;
