import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import PropertyPortfolioService from "../../../services/property-portfolio.service";
// import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import defaultImage from "assets/img/default-avatar.png";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../store";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import Spinner from "utils/spinner";

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  // const [clientId, setClientId] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [state, setState] = useAtom(selectedClientAtom);
  const [confirmationOpen, setConfirmationOpen] = useState(
    Array(properties.length).fill(false)
  );
  const [showSpinner, setShowSpinner] = useState(true);
  const history = useHistory();

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient?.clientId);
    getProperties(state?.clientId);
  }, [isDataLoaded, state?.clientId]);

  const getProperties = async (id) => {
    return PropertyPortfolioService.getAllByClientId(id)
      .then((response) => {
        let propertyData = response.data.recordset.map((property) => {
          return {
            id: property.ClientPropertyId,
            address: property.Address,
            propertyType: property.PropertyType,
            dwellingType: property.DwellingType,
            noOfBeds: property.NoOfBeds,
            noOfBaths: property.NoOfBaths,
            noOfCarSpaces: property.NoOfCarSpaces,
            currentMarketValue: property.CurrentMarketValue,
            excludeFromCalculation: property.ExcludeFromCalculation,
            yearBuild: property.YearBuild,
            landSize: property.LandSize,
            floorSize: property.FloorSize,
            ownerType: property.OwnerType,
            ownerName: property.OwnerName,
            ownershipPercent: property.OwnershipPercent,
            underSeparateEntity: property.UnderSeparateEntity,
            dateSettled: property.DateSettled,
            purchasePrice: property.PurchasePrice,
            stampDutyCost: property.StampDutyCost,
            lmiCost: property.LmiCost,
            conveyencingCost: property.ConveyencingCost,
            additionalCost: property.AdditionalCost,
            depreciationScheduleAvailable:
              property.DepreciationScheduleAvailable,
            annualDepreciation: property.AnnualDepreciation,
            currentLoanAmount: property.CurrentLoanAmount,
            mostRecentLoanCommencedYear: property.MostRecentLoanCommencedYear,
            lender: property.Lender,
            currentInterestRate: property.CurrentInterestRate,
            originalLoanTerm: property.OriginalLoanTerm,
            loanType: property.LoanType,
            interestOnlyTerm: property.InterestOnlyTerm,
            interestRateStructure: `${property.InterestRateStructure}`,
            fixedTerm: property.FixedTerm,
            autoCalculateMortgageRepyment:
              property.AutoCalculateMortgageRepyment,
            monthlyRepayment: property.MonthlyRepayment,
            currentRepaymentAmount: property.CurrentRepaymentAmount,
            currentRepaymentFrequency: `${property.CurrentRepaymentFrequency}`,
            splitLoan: property.SplitLoan,
            splitLoanAmount: property.SplitLoanAmount,
            splitLoanDateBorrowed: `${property.SplitLoanDateBorrowed}`,
            splitLoanCurrentInterestRate: property.SplitLoanCurrentInterestRate,
            splitLoanTerm: property.SplitLoanTerm,
            splitLoanType: property.SplitLoanType,
            splitLoanInetrOnlyTerm: property.SplitLoanInetrOnlyTerm,
            splitLoanInterestRateStructure:
              property.SplitLoanInterestRateStructure,
            splitLoanFixedTerm: property.SplitLoanFixedTerm,
            autoCalculateSplitLoanMortgageRepyment:
              property.AutoCalculateSplitLoanMortgageRepyment,
            splitLoanMonthlyRepayment: property.SplitLoanMonthlyRepayment,
            splitLoanCurrentRepaymentAmount:
              property.SplitLoanCurrentRepaymentAmount,
            splitLoanRepaymentFrequency: `${property.SplitLoanRepaymentFrequency}`,
            lastRenovationAmount: property.LastRenovationAmount,
            lastRenovationDate: `${property.LastRenovationDate}`,
            lastRenovationDesc: property.LastRenovationDesc,
            currentPropertyManager: property.CurrentPropertyManager,
            managementPercentage: property.ManagementPercentage,
            rentalIncomePerWeek: property.RentalIncomePerWeek,
            externalLink: property.ExternalLink,
            propertyExpensesCalculationMethod:
              property.PropertyExpensesCalculationMethod,
            strataCost: property.StrataCost,
            strataCostFrequency: `${property.StrataCostFrequency}`,
            councilCost: property.CouncilCost,
            councilCostFrequency: `${property.CouncilCostFrequency}`,
            insurnaceCost: property.InsurnaceCost,
            insurnaceCostFrequency: `${property.InsurnaceCostFrequency}`,
            maintenanceCost: property.MaintenanceCost,
            maintenanceCostFrequency: `${property.MaintenanceCostFrequency}`,
            landTaxAmount: property.LandTaxAmount,
            landTaxAmountFrequency: `${property.LandTaxAmountFrequency}`,
            accountingCost: property.AccountingCost,
            accountingCostFrequency: `${property.AccountingCostFrequency}`,
            asicCost: property.AsicCost,
            asicCostFrequency: `${property.AsicCostFrequency}`,
            waterCost: property.WaterCost,
            waterCostFequency: `${property.WaterCostFequency}`,
            electricalCost: property.ElectricalCost,
            electricalCostFrequency: `${property.ElectricalCostFrequency}`,
            gasCost: property.GasCost,
            gasCostFrequency: property.GasCostFrequency,
            internetCost: property.InternetCost,
            internetCostFrequency: `${property.InternetCostFrequency}`,
            totalExpenses: property.TotalExpenses,
            totalExpensesFrequency: property.TotalExpensesFrequency,
            clientId: property?.clientId,
          };
        });

        setProperties(propertyData);
        if (response.data.recordsets.length > 0) {
          console.log("response: ", response);
          console.log("properties: ", propertyData);
          ///setIsDataLoaded(true);
          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(false);
        }
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setProperties([]);
        setShowSpinner(false);
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#66615B", //theme.palette.common.darkgray,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleConfirmationOpen = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = true;
    setConfirmationOpen(tempOpens);
  };

  const handleConfirmationClose = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = false;
    setConfirmationOpen(tempOpens);
  };

  const handleEdit = (e, propertyPortfolioId) => {
    console.log("property portfolio id: ", propertyPortfolioId);
    e.preventDefault();
    const path = `/admin/scoreboard/property-portfolio/add/${propertyPortfolioId}`;
    history.push(path);
  };

  const handleDetails = (e, propertyPortfolioId) => {
    console.log("property portfolio id: ", propertyPortfolioId);
    e.stopPropagation();
    e.preventDefault();
    const path = `/admin/scoreboard/property-portfolio/details/${propertyPortfolioId}`;
    history.push(path);
  };

  const handleAdd = () => {
    const path = "/admin/scoreboard/property-portfolio/add";
    history.push(path);
  };

  const handleDelete = async (propertyId) => {
    console.log("delete property");
    return PropertyPortfolioService.delete(propertyId).then((response) => {
      console.log("response: ", response.data.recordset);
      getProperties(state?.clientId).then(() => setSuccessDialogOpen(true));
    });
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="content property-portfolio-content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Property is deleted."
        action={action}
      />
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Property List for {state?.firstName} {state?.lastName}
                </CardTitle>
                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {/* {isDataLoaded && (
                                <DataGrid
                                    rows={familyMembers}
                                    columns={columns}
                                />
                            )} */}

                <Row>
                  {isDataLoaded &&
                    properties.map((property, index) => (
                      <Col lg="3">
                        <DeleteDialog
                          confirmationOpen={confirmationOpen[index]}
                          handleConfirmationClose={() =>
                            handleConfirmationClose(index)
                          }
                          onDelete={() => handleDelete(property.id)}
                        />
                        <Card className="card-pricing">
                          <CardHeader className="card-cross-btn">
                            {/* <Link to={`./add/${property.id}`}> */}
                            {/* </Link> */}
                            <div className="protfolio-list-header">
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleConfirmationOpen(index)}
                              >
                                <CloseSharpIcon />
                              </IconButton>
                            </div>
                          </CardHeader>
                          <Link to={`./add/${property.id}`}>
                            <CardBody className="member_card">
                              <div className="card-icon icon-warning">
                                <i className="nc-icon nc-bank" />
                              </div>
                              <CardTitle tag="h4">
                                {property.propertyType}
                              </CardTitle>
                              <ul>
                                {property.address ? (
                                  <li>{property.address}</li>
                                ) : (
                                  <li></li>
                                )}
                                {property.dwellingType ? (
                                  <li>{property.dwellingType}</li>
                                ) : (
                                  <li></li>
                                )}
                              </ul>
                            </CardBody>
                          </Link>
                          <CardFooter>
                            <Button
                              className="btn-round"
                              color="primary"
                              href="#pablo"
                              onClick={(e) => handleDetails(e, property.id)}
                            >
                              Detail
                            </Button>
                          </CardFooter>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyList;
