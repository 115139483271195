import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap";

import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import FamilyMemberService from "../../../../services/family-member.service";
import "moment/locale/en-gb";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";

const FamilySaving = ({
  formData,
  setFormData,
  defaultAssumptions,
  validationErrorsSavingDetails,
}) => {
  const [selectedFamilyMember, setSelectedFamilyMember] = useState("");
  const [familyMemberNames, setFamilyMemberNames] = useState([]);
  const [selectedFamilyMemberId, setSelectedFamilyMemberId] = useState({});
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  // const [clientId, setClientId] = useState(1);
  // const [userId, setUserId] = useState(1);

  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length < 5
      ? false
      : true;

  console.log("isupdate: ", isUpdate);

  const getFamilyMemberNamesByClientId = async (id) => {
    FamilyMemberService.getFamilyMemberNamesByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const names = response.data.recordset;
          console.log("names: ", names);
          let optionItems = names.map((item) => ({
            label: `${item.FirstName} ${item.LastName}`,
            value: item.FamilyMemberId,
          }));
          setFamilyMemberNames(optionItems);
        }
      })
      .catch((error) => {
        console.log(error);
        setFamilyMemberNames([]);
      });
  };

  const handleMonthlyAverageSavingChange = (value) => {
    setFormData({
      ...formData,
      avgMonthlySaving: value,
      isDefaultMonthlyAvgSaving: false,
    });
    console.log("set for mdata handleMonthlyAverageSavingChange: ", formData);
  };

  const handleMonthlyExpensesChange = (value) => {
    setFormData({
      ...formData,
      monthlyExpenses: value,
      isDefaultMonthlyExpenses: false,
    });
    console.log("set for mdata handleMonthlyExpensesChange: ", formData);
  };

  const handleMonthlySpendingsChange = (value) => {
    setFormData({
      ...formData,
      monthlyLifeStyleSpending: value,
      isDefaultMonthlyLifeStyleSpending: false,
    });
    console.log("set form data handleMonthlySpendingsChange: ", formData);
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    //console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient.clientId);
    if (isUpdate) {
      // getEmploymentById();
    } else {
      getFamilyMemberNamesByClientId(clientState.clientId);
    }
  }, [clientState.clientId]);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Add Savings for {clientState.firstName} {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                {!isUpdate ? (
                  <FormGroup>
                    {validationErrorsSavingDetails.familyMember ? (
                      <span
                        style={{
                          color: "red",
                          fontWeight: 600,
                          padding: "0px 0px 10px 0px",
                        }}
                      >
                        {validationErrorsSavingDetails.familyMember}
                      </span>
                    ) : (
                      <Label>Select Family Member</Label>
                    )}

                    <Select
                      name=""
                      className="react-select select-option-control"
                      placeholder=""
                      classNamePrefix="react-select"
                      value={formData.familyMember}
                      onChange={(familyMember) =>
                        setFormData({
                          ...formData,
                          familyMember: familyMember,
                        })
                      }
                      options={familyMemberNames}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label>Family Member</Label>
                    <Input
                      placeholder=""
                      type="text"
                      value={formData.familyMember}
                      disabled={true}
                    />
                  </FormGroup>
                )}
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>Monthly Average Savings </Label>
                  <CurrencyInput
                    placeholder="Average Monthly Savings"
                    number={formData.avgMonthlySaving}
                    handleNumberChange={(value) =>
                      handleMonthlyAverageSavingChange(value)
                    }
                  />
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {defaultAssumptions.DefaultMonthlyAvgSavings}
                    </strong>{" "}
                    would be selected as default)
                  </Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Monthly Expenses </Label>
                  <CurrencyInput
                    placeholder="Monthly Expenses"
                    number={formData.monthlyExpenses}
                    handleNumberChange={(value) =>
                      handleMonthlyExpensesChange(value)
                    }
                  />
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.DefaultMonthlyExpenses}</strong>{" "}
                    would be selected as default)
                  </Typography>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>Monthly Spending on Life Style </Label>
                  <CurrencyInput
                    placeholder="Monthly Life Style Spending"
                    number={formData.monthlyLifeStyleSpending}
                    handleNumberChange={(value) =>
                      handleMonthlySpendingsChange(value)
                    }
                  />
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.DefaultMonthlySpending}</strong>{" "}
                    would be selected as default)
                  </Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <div className="Checkbox">
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formData.needHelpWithBudget}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            needHelpWithBudget: !formData.needHelpWithBudget,
                          })
                        }
                      />
                      <span className={`form-check-sign`} />
                      Need help with budget
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default FamilySaving;
