import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Checkbox from "@mui/material/Checkbox";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency, currencyToNumber } from "utils/currency";
import { Container } from "@mui/material";

const PropertyCost = ({
  formData,
  setFormData,
  validationErrorsCostDetails,
}) => {
  const [state, setState] = useAtom(selectedClientAtom);
  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    height: "65px",
    width: "175px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const UpdatePurchasePrice = (purchaseValue) => {
    const stampDuty = formatCurrency(
      Math.round((currencyToNumber(purchaseValue) * 5) / 100, 2)
    );
    setFormData({
      ...formData,
      purchasePrice: purchaseValue,
      stampDutyCost: stampDuty,
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Set your property portfolio for {state.firstName} {state.lastName}{" "}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                {validationErrorsCostDetails.purchasePrice ? (
                  <span style={{ color: "red", fontWeight: 600 }}>
                    {validationErrorsCostDetails.purchasePrice} Purchase Price
                  </span>
                ) : (
                  <Label>Purchase Price</Label>
                )}

                <FormGroup>
                  <CurrencyInput
                    placeholder="Purchase price"
                    number={formData.purchasePrice}
                    handleNumberChange={
                      (value) => UpdatePurchasePrice(value)
                      // setFormData({ ...formData, purchasePrice: value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <Label>Stamp duty cost</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Stamp duty cost"
                    number={formData.stampDutyCost}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, stampDutyCost: value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label>Lenders mortgage insurance cost</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="LMI cost"
                    number={formData.lenderMortgageInsuranceCost}
                    handleNumberChange={(value) =>
                      setFormData({
                        ...formData,
                        lenderMortgageInsuranceCost: value,
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <Label>Conveyencing cost</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Conveyencing cost"
                    number={formData.conveyencingCost}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, conveyencingCost: value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Additional cost</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Additional cost"
                    number={formData.additionalCost}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, additionalCost: value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <div className="Checkbox padding-class">
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formData.depreciationScheduleAvailable}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            depreciationScheduleAvailable:
                              !formData.depreciationScheduleAvailable,
                          })
                        }
                      />
                      <span className={`form-check-sign`} />
                      Have depreciation schedule
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Label>What is the annual depreciation?</Label>
                <FormGroup>
                  <Input
                    placeholder="0"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    value={formData.annualDepreciation}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        annualDepreciation: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PropertyCost;
