import React, { useEffect, useState } from "react";

// reactstrap components
import {
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Label,
} from "reactstrap";
import PropertyPortfolioService from "../../../services/property-portfolio.service";
// import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import defaultImage from "assets/img/default-avatar.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../store";
import land_size from "../../../assets/img/land_size.svg";
import floor_size from "../../../assets/img/floor_size.svg";
import year_built from "../../../assets/img/year_built.svg";
import building_type from "../../../assets/img/building_type.svg";
import renovation_cost from "../../../assets/img/renovation_cost.svg";
import purchase_year from "../../../assets/img/purchase_year.svg";
import lender from "../../../assets/img/lender.svg";
import interest_rate from "../../../assets/img/interest_rate.svg";
import loan_type from "../../../assets/img/loan_type.svg";
import loan_structure from "../../../assets/img/loan_structure.svg";
import clock from "../../../assets/img/clock.svg";
import agency from "../../../assets/img/agency.svg";
import management_fee from "../../../assets/img/management_fee.svg";
import rental_income from "../../../assets/img/rental_income.svg";
import rental_yield from "../../../assets/img/rental_yield.svg";
import yield_on_loan_amount from "../../../assets/img/yield_on_loan_amount.svg";
import yield_on_purchase_price from "../../../assets/img/yield_on_purchase_price.svg";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import { formatCurrency } from "utils/currency";
import Spinner from "utils/spinner";

const PropertyFinancialDetails = () => {
  const [properties, setProperties] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // const [clientId, setClientId] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [propertyFinancials, setPropertyFinancials] = useState({});
  const [state, setState] = useAtom(selectedClientAtom);
  const [showSpinner, setShowSpinner] = useState(true);
  const [propertyId, setPropertyId] = useState(0);

  const history = useHistory();

  const redirectToPropertyDetails = () => {
    const path = `/admin/scoreboard/property-portfolio/add/${propertyId}`;
    history.push(path);
  };
  const getPropertyId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };
  const getPropertyFinancials = async (id) => {
    PropertyPortfolioService.getFinancials(id)
      .then((response) => {
        // setProperties(propertyData);
        if (response.data.recordsets.length > 0) {
          console.log("response: ", response);
          setPropertyFinancials(response.data.recordset[0]);
          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(false);
        }
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setPropertyFinancials({});
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    //  console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient.clientId);
    const propertyId = getPropertyId();
    setPropertyId(propertyId);
    getPropertyFinancials(propertyId);
  }, [isDataLoaded, state.clientId]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#66615B", //theme.palette.common.darkgray,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleEditClick = () => {
    const propertyId = getPropertyId();
    console.log("propertyId:", propertyId);
    const path = `/admin/scoreboard/property-portfolio/add/${propertyId}`;
    history.push(path);
  };

  const handleAdd = () => {
    const path = "/admin/scoreboard/property-portfolio/add";
    history.push(path);
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="content  property-financial-details">
      <ClientNotSelectedDialog />
      <Container>
        <Row onClick={() => redirectToPropertyDetails()}>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {/* Property Financial Details - {propertyFinancials.Address} */}
                  {`Property Financial Details ${
                    propertyFinancials.Address
                      ? `- ${propertyFinancials.Address}`
                      : ""
                  }`}
                </CardTitle>

                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="property-financial-body">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="12">
                        <Box sx={{ minWidth: 275 }}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h5" component="div">
                                PROPERTY DETAILS - INVESTMENT
                              </Typography>
                              <div className="property-card-warpper">
                                <div className="property-card-inner">
                                  <img alt="..." src={land_size} />
                                  <div>
                                    <p className="property-title">Land size</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.LandSize
                                        ? propertyFinancials.LandSize
                                        : "0.0"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={floor_size} />
                                  <div>
                                    <p className="property-title">Floor size</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.FloorSize
                                        ? propertyFinancials.FloorSize
                                        : "0.0"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={year_built} />
                                  <div>
                                    <p className="property-title">Year build</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.YearBuild
                                        ? propertyFinancials.YearBuild
                                        : "YYYY"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={building_type} />
                                  <div>
                                    <p className="property-title">
                                      Building type
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.BuildingType
                                        ? propertyFinancials.BuildingType
                                        : "on"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={renovation_cost} />
                                  <div>
                                    <p className="property-title">
                                      Renovation cost
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.LastRenovationAmount
                                        ? formatCurrency(
                                            propertyFinancials.LastRenovationAmount
                                          )
                                        : " $ 0.00"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={purchase_year} />
                                  <div>
                                    <p className="property-title">
                                      Purchase year
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.PurchaseYear
                                        ? propertyFinancials.PurchaseYear
                                        : "YYYY"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Col>

                      <Col md="12">
                        <Box sx={{ minWidth: 275 }}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h5" component="div">
                                LOAN DETAILS
                              </Typography>
                              <div className="property-card-warpper">
                                <div className="property-card-inner">
                                  <img alt="..." src={lender} />
                                  <div>
                                    <p className="property-title">Lender</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.Lender
                                        ? propertyFinancials.Lender
                                        : ""}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={interest_rate} />
                                  <div>
                                    <p className="property-title">
                                      Interest Rate
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.CurrentInterestRate
                                        ? propertyFinancials.CurrentInterestRate
                                        : "0.00"}
                                      {"%"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={loan_type} />
                                  <div>
                                    <p className="property-title">Loan type</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.LoanType
                                        ? propertyFinancials.LoanType
                                        : "on"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={loan_structure} />
                                  <div>
                                    <p className="property-title">
                                      Loan Structure
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.InterestRateStructure
                                        ? propertyFinancials.InterestRateStructure
                                        : "on"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={clock} />
                                  <div>
                                    <p className="property-title">
                                      IO Expiry date
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.EndInterestOnlyTerm
                                        ? propertyFinancials.EndInterestOnlyTerm
                                        : "Feb 24"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={clock} />
                                  <div>
                                    <p className="property-title">
                                      Fixed Expiry date
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.EndFixedTerm
                                        ? propertyFinancials.EndFixedTerm
                                        : "Feb 24"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Col>

                      <Col md="12">
                        <Box sx={{ minWidth: 275 }}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h5" component="div">
                                Rental Details
                              </Typography>
                              <div className="property-card-warpper">
                                <div className="property-card-inner">
                                  <img
                                    className="agency_img"
                                    alt="..."
                                    src={agency}
                                  />
                                  <div>
                                    <p className="property-title">Agency</p>
                                    <p className="property-title-value">
                                      {propertyFinancials.Agency
                                        ? formatCurrency(
                                            propertyFinancials.Agency
                                          )
                                        : "$ 0"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={management_fee} />
                                  <div>
                                    <p className="property-title">
                                      Management Percentage
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.ManagementPercentage
                                        ? propertyFinancials.ManagementPercentage
                                        : "0.00"}
                                      {"%"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={rental_income} />
                                  <div>
                                    <p className="property-title">
                                      Rental Income
                                    </p>
                                    <p className="property-title-value">
                                      {"$"}
                                      {propertyFinancials.RentalIncomePerWeek
                                        ? propertyFinancials.RentalIncomePerWeek
                                        : "0"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={rental_yield} />
                                  <div>
                                    <p className="property-title">
                                      Rental Yield
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.RentalYield
                                        ? propertyFinancials.RentalYield
                                        : "0.00"}
                                      {"%"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img alt="..." src={yield_on_loan_amount} />
                                  <div>
                                    <p className="property-title">
                                      Yield on loan amount
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.YieldOnLoanAmount
                                        ? propertyFinancials.YieldOnLoanAmount.toFixed(
                                            2
                                          )
                                        : "0.00"}
                                      {"%"}
                                    </p>
                                  </div>
                                </div>

                                <div className="property-card-inner">
                                  <img
                                    alt="..."
                                    src={yield_on_purchase_price}
                                  />
                                  <div>
                                    <p className="property-title">
                                      Yield on purchase price
                                    </p>
                                    <p className="property-title-value">
                                      {propertyFinancials.YieldOnPurchasePrice
                                        ? propertyFinancials.YieldOnPurchasePrice.toFixed(
                                            2
                                          )
                                        : "0.00"}
                                      {"%"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Col>
                    </Row>
                  </Col>

                  <Col md="4">
                    <Row className="property-side-body">
                      <Col md="12">
                        <div className="property-side-body-inner">
                          <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="div">
                                  Purchase Details
                                </Typography>
                                <div className="property-card-warpper">
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Purchase Price
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.PurchasePrice
                                          ? formatCurrency(
                                              propertyFinancials.PurchasePrice
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Current Price
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.CurrentMarketValue
                                          ? formatCurrency(
                                              propertyFinancials.CurrentMarketValue
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Box>

                          <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="div">
                                  Growth Details
                                </Typography>
                                <div className="property-card-warpper">
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Growth since purchase
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.Growth
                                          ? propertyFinancials.Growth.toFixed(2)
                                          : "0"}
                                        {"%"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Compound Growth rate PA
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.CompoundGrowth
                                          ? propertyFinancials.CompoundGrowth.toFixed(
                                              2
                                            )
                                          : "0"}
                                        {"%"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Box>

                          <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="div">
                                  Current Loan Details
                                </Typography>
                                <div className="property-card-warpper">
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Current Loan
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.CurrentLoan
                                          ? formatCurrency(
                                              propertyFinancials.CurrentLoan
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Loan to value ratio
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.LoanToValueRatio
                                          ? Number(
                                              propertyFinancials.LoanToValueRatio
                                            ).toFixed(2)
                                          : "0"}
                                        {"%"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Current repayment amount
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.CurrentRepaymentAmount
                                          ? formatCurrency(
                                              propertyFinancials.CurrentRepaymentAmount
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Box>

                          <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="div">
                                  Equity Details
                                </Typography>
                                <div className="property-card-warpper">
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Total Equity
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.Equity
                                          ? formatCurrency(
                                              propertyFinancials.Equity
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Borrowable Equity 80% LVR
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.BorrowableEquity
                                          ? formatCurrency(
                                              propertyFinancials.BorrowableEquity
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Box>

                          <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="div">
                                  Rental Details
                                </Typography>
                                <div className="property-card-warpper">
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Income PA
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.IncomePA
                                          ? formatCurrency(
                                              propertyFinancials.IncomePA
                                            )
                                          : "0"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Expenses PA
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.TotalExpenses
                                          ? formatCurrency(
                                              propertyFinancials.TotalExpenses
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Cashflow PA
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.Cashflow
                                          ? formatCurrency(
                                              propertyFinancials.Cashflow
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="property-card-inner">
                                    <div>
                                      <p className="property-title">
                                        Gearing Cashflow PA
                                      </p>
                                      <p className="property-title-value">
                                        {propertyFinancials.GearingCashflow
                                          ? formatCurrency(
                                              propertyFinancials.GearingCashflow
                                            )
                                          : "$ 0"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyFinancialDetails;
