import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import "moment/locale/en-gb";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import "react-datetime/css/react-datetime.css";
import Typography from "@mui/material/Typography";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";

const PropertyDetails = ({
  formData,
  setFormData,
  validationErrorsPropertyDetails,
  defaultAssumptions,
}) => {
  // const [clientId, setClilentId] = useState(1);
  // const [userId, setUserId] = useState(1);
  const [state, setState] = useAtom(selectedClientAtom);
  const propertyType = [
    "Principal place of residence",
    "Residential",
    "Commercial",
  ];
  const dwellingType = [
    "House",
    "Semi-detached",
    "Townhouse",
    "Duplex",
    "Unit/flat",
    "Apartment",
    "Renovation",
    "Development site",
    "Block of units",
    "Block of land",
    "Shopfront",
    "Strip of shops",
    "Shopping mall",
    "Warehouse",
    "Factory",
  ];
  const noOfBeds = ["0", "1", "2", "3", "4", "5+"];
  const noOfBaths = ["0", "1", "2", "3", "4", "5+"];
  const noOfCarSpaces = ["0", "1", "2", "3", "4", "5+"];

  const propertyTypeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#114253",
    border: "1px solid #DDDDDD",
    borderRadius: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const sellingBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const noOfBedsBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setDwellingType = (dwellingType) => {
    setFormData({ ...formData, dwellingType });
  };

  const setNoOfBedRooms = (noOfBeds) => {
    setFormData({ ...formData, noOfBeds });
  };

  const setNoOfBathRooms = (noOfBaths) => {
    setFormData({ ...formData, noOfBaths });
  };

  const setNoOfCarSpaces = (noOfCarSpaces) => {
    setFormData({ ...formData, noOfCarSpaces });
  };

  const setPropertyType = (propertyType) => {
    setFormData({
      ...formData,
      propertyType: propertyType,
      isDefaultPropertyType: "0",
    });
    console.log("Property Type - ", propertyType);
  };

  useEffect(() => {}, [state.clientId]);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Set your property portfolio for {state.firstName} {state.lastName}{" "}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                {validationErrorsPropertyDetails.address ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      padding: "0px 0px 10px 0px",
                    }}
                  >
                    {validationErrorsPropertyDetails.address} Address
                  </span>
                ) : (
                  <Label>Address</Label>
                )}
                <FormGroup>
                  <Input
                    placeholder="Address"
                    type="text"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Property Type</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {propertyType.map((pt) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={pt}
                      >
                        <Box
                          onClick={() => setPropertyType(pt)}
                          key={pt}
                          sx={propertyTypeBoxSx}
                          style={{
                            backgroundColor:
                              formData.propertyType === pt
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {pt}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.PropertyType}</strong> would be
                    selected as default )
                  </Typography>

                  {/* <span>
                    (Note: If not selected <strong>Residential</strong> would be
                    selected as default )
                  </span> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Dwelling Type</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {dwellingType.map((dt) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={dt}
                      >
                        <Box
                          onClick={() => setDwellingType(dt)}
                          key={dt}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.dwellingType === dt
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {dt}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many beds?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfBeds.map((nb) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nb}
                      >
                        <Box
                          onClick={() => setNoOfBedRooms(nb)}
                          key={nb}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfBeds === nb ? "#e61a4b" : "#114253",
                          }}
                        >
                          {nb}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many baths?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfBaths.map((nb) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nb}
                      >
                        <Box
                          onClick={() => setNoOfBathRooms(nb)}
                          key={nb}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfBaths === nb ? "#e61a4b" : "#114253",
                          }}
                        >
                          {nb}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many car spaces?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfCarSpaces.map((nc) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nc}
                      >
                        <Box
                          onClick={() => setNoOfCarSpaces(nc)}
                          key={nc}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfCarSpaces === nc
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {nc}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                {validationErrorsPropertyDetails.currentMarketValue ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      padding: "0px 0px 10px 0px",
                    }}
                  >
                    {validationErrorsPropertyDetails.currentMarketValue} Current
                    market value
                  </span>
                ) : (
                  <Label>Current market value</Label>
                )}

                <FormGroup>
                  <CurrencyInput
                    placeholder="Current Market Value"
                    number={formData.currentMarketValue}
                    handleNumberChange={(value) => {
                      setFormData({
                        ...formData,
                        currentMarketValue: value,
                        rentalIncomePerWeek: formatCurrency(
                          (currencyToNumber(value) *
                            defaultAssumptions.RentalYield) /
                            100 /
                            52
                        ),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <div className="Checkbox padding-class">
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formData.excludeFromCalculation}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            excludeFromCalculation:
                              !formData.excludeFromCalculation,
                          })
                        }
                      />
                      <span className={`form-check-sign`} />
                      Exclude this property from all calcualtion?
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="4" />
            <Col md="8"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PropertyDetails;
