import React, { useEffect, useState } from "react";

import assumptionService from "services/assumption.service";
import { useAtom } from "jotai";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { selectedClientAtom } from "store";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { max } from "date-fns";
const Compare = () => {
  const [state, setState] = useAtom(selectedClientAtom);

  const [clientId, setClientId] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAssumptionIds, setSelectedAssumptionIds] = useState([]);
  const [assumptions, setAssumptions] = useState([]);
  const [assumptionNames, setAssumptionNames] = useState([]);
  const [clientProfileAnalysis1, setClientProfileAnalysis1] = useState([]);
  const [clientProfileAnalysis2, setClientProfileAnalysis2] = useState([]);

  const maxSelection = 2;
  const minSelection = 2;

  useEffect(() => {
    setClientId(state.clientId);
    getClientAssumptions(state.clientId);
  }, []);

  const getClientProfileAnalysis = (async) => {
    if (selectedAssumptionIds.length < 2) return;

    let assumptionId = selectedAssumptionIds[0];
    let data = {
      clientId,
      assumptionId,
    };
    console.log("Data 1", data);

    assumptionService
      .applyAssumption(data)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          setClientProfileAnalysis1(response.data.recordsets);
          console.log("apply assumptions 1: ", clientProfileAnalysis1);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    assumptionId = selectedAssumptionIds[1];
    console.log("Assumption Id - ", assumptionId);
    if (assumptionId !== undefined) {
      data = {
        clientId,
        assumptionId,
      };
      console.log("Data 2", data);

      assumptionService
        .applyAssumption(data)
        .then((response) => {
          if (response.data.recordset.length > 0) {
            setClientProfileAnalysis2(response.data.recordsets);
            console.log("apply assumptions 2: ", clientProfileAnalysis2);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getClientAssumptions = async (id) => {
    assumptionService
      .getDefaultAndClientAssumptions(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const assumptions = response.data.recordset;
          console.log("assumptions: ", assumptions);
          let optionItems = assumptions.map((item) => item.AssumptionName);
          setAssumptions(assumptions);
          setAssumptionNames(optionItems);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleOptionToggle = (options) => {
  //   setSelectedOptions(options);
  // };

  const handleOptionToggle = (options) => {
    setSelectedOptions(options);

    const selectedIds = assumptions
      .filter((assumption) =>
        options.some(
          (selectedOption) => assumption.AssumptionName === selectedOption
        )
      )
      .map((assumption) => assumption.AssumptionId);

    setSelectedAssumptionIds(selectedIds);
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Compare Assumptions</CardTitle>
              {/* <p className="card-category">All products including Taxes</p> */}
            </CardHeader>
            <CardBody>
              <MultiSelect
                options={assumptionNames}
                maxSelection={maxSelection}
                onChange={handleOptionToggle}
              />
            </CardBody>
            <CardFooter>
              <Button
                sx={{ float: "right" }}
                variant="contained"
                color="grey"
                size="medium"
                onClick={() => {
                  console.log(
                    "Selected Assumption IDs:",
                    selectedAssumptionIds
                  );
                  console.log(
                    assumptions.filter((assumption) =>
                      selectedOptions.some(
                        (selectedOption) =>
                          assumption.AssumptionName === selectedOption
                      )
                    )
                  );
                  getClientProfileAnalysis();
                }}
              >
                COMPARE
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Compare;
