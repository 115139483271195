import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import MoneyManagementService from "../../../services/money-management.service";
import FamilyMemberService from "services/family-member.service";

import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import defaultImage from "assets/img/default-avatar.png";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import Spinner from "utils/spinner";

const MoneyManagement = () => {
  const [moneyMembers, setMoneyMembers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [clientId, setClientId] = useState(0);
  const [familyMemberCount, setFamilyMemberCount] = useState(0);
  const [familyMemberDataCount, setFamilyMemberDataCount] = useState(0);

  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [selectedClientState, setSelectedClientState] =
    useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [showSpinner, setShowSpinner] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(
    Array(moneyMembers.length).fill(false)
  );
  // const [isDefaultMonthlyAvgSaving, setIsDefaultMonthlyAvgSaving] =
  //   useState(true);
  // const [isDefaultMonthlyExpenses, setIsDefaultMonthlyExpenses] =
  //   useState(true);
  // const [
  //   isDefaultMonthlyLifeStyleSpending,
  //   setIsDefaultMonthlyLifeStyleSpending,
  // ] = useState(true);
  // const [isDefaultGrossAnnualIncome, setIsDefaultGrossAnnualIncome] =
  //   useState(true);
  // const [isDefaultIncomeTaxRate, setIsDefaultIncomeTaxRate] = useState(true);
  // const [isDefaultTotalLiquidSavings, setIsDefaultTotalLiquidSavings] =
  //   useState(true);
  // const [isDefaultTotalInSuper, setIsDefaultTotalInSuper] = useState(true);

  const history = useHistory();

  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length < 5
      ? false
      : true;

  const getFamilyMemberNamesByClientId = async (id) => {
    FamilyMemberService.getFamilyMemberNamesByClientId(id)
      .then((response) => {
        setFamilyMemberCount(response.data.recordset.length);
      })
      .catch((error) => {
        console.log(error);
        setFamilyMemberCount(0);
      });
  };

  const getFamilyMoneyManagement = async (id) => {
    return MoneyManagementService.getAllByClientId(id)
      .then((response) => {
        console.log("Get Family Money Management", response.data.recordset);
        let familyMemberMoneyManagementData = response.data.recordset.map(
          (moneyMember) => {
            return {
              id: moneyMember.MoneyManagementId,
              familyMemberId: moneyMember.FamilyMemberId,
              firstName: moneyMember.FirstName,
              lastName: moneyMember.LastName,
              avgMonthlySaving: moneyMember.MonthlyAvgSaving,
              monthlyExpenses: moneyMember.MonthlyExpenses,
              monthlyLifeStyleSpending: moneyMember.MonthlyLifeStyleSpending,
              needHelpWithBudget: moneyMember.NeedHelpWithBudget,
              grossAnnualIncome: moneyMember.GrossAnnualIncome,
              totalLiquidSaving: moneyMember.TotalLiquidSaving,
              totalInSuper: moneyMember.TotalInSuper,
              allocateIncomeToSuper: moneyMember.AllocateIncomeToSuper,
              incomeTaxRate: moneyMember.IncomeTaxRate,
              isDefaultMonthlyAvgSaving:
                response.data.recordset[0]?.IsDefaultMonthlyAvgSaving,
              isDefaultMonthlyExpenses:
                response.data.recordset[0]?.IsDefaultMonthlyExpenses,
              isDefaultMonthlyLifeStyleSpending:
                response.data.recordset[0]?.IsDefaultMonthlyLifeStyleSpending,
              isDefaultGrossAnnualIncome:
                response.data.recordset[0]?.IsDefaultGrossAnnualIncome,
              isDefaultIncomeTaxRate:
                response.data.recordset[0]?.IsDefaultIncomeTaxRate,
              isDefaultTotalInSuper:
                response.data.recordset[0]?.IsDefaultTotalInSuper,
              isDefaultTotalLiquidSavings:
                response.data.recordset[0]?.isDefaultTotalLiquidSavings,
            };
          }
        );
        setFamilyMemberDataCount(familyMemberMoneyManagementData.length);

        setMoneyMembers(familyMemberMoneyManagementData);
        if (response.data.recordsets.length > 0) {
          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(false);
        }
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setMoneyMembers([]);
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    console.log("Use Effect", selectedClientState.clientId);
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      console.log("Use Effect", selectedClientState.clientId);
      getFamilyMoneyManagement(selectedClientState.clientId);
      getFamilyMemberNamesByClientId(selectedClientState.clientId);
    }
  }, [selectedClientState.clientId]);

  const handleConfirmationOpen = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = true;
    setConfirmationOpen(tempOpens);
  };

  const handleConfirmationClose = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = false;
    setConfirmationOpen(tempOpens);
  };

  const handleClick = (e, familyMemberId) => {
    console.log("family member id: ", familyMemberId);
    e.preventDefault();
    const path = `/admin/scoreboard/money-management/add/${familyMemberId}`;
    history.push(path);
  };

  const handleAdd = () => {
    // Check for All members Add
    if (familyMemberDataCount < familyMemberCount) {
      const path = "/admin/scoreboard/money-management/add";
      history.push(path);
    }
  };

  const handleDelete = async (moneyManagementId) => {
    return MoneyManagementService.delete(moneyManagementId).then((response) => {
      console.log("response: ", response.data.recordset);
      getFamilyMoneyManagement(selectedClientState.clientId).then(() =>
        setSuccessDialogOpen(true)
      );
    });
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="content money-management-content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Money management is deleted."
        action={action}
      />
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Money Management for {selectedClientState.firstName}{" "}
                  {selectedClientState.lastName}
                </CardTitle>
                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {isDataLoaded &&
                    moneyMembers.map((member, index) => (
                      <Col md="6" lg="3" key={index}>
                        <DeleteDialog
                          confirmationOpen={confirmationOpen[index]}
                          handleConfirmationClose={() =>
                            handleConfirmationClose(index)
                          }
                          onDelete={() => handleDelete(member.familyMemberId)}
                        />
                        <Card className="card-pricing money_management member_card">
                          <CardHeader className="card-cross-btn">
                            <IconButton
                              aria-label="delete"
                              style={{
                                marginLeft: "auto",
                                padding: 0,
                                color: "#e61a4b",
                              }}
                              onClick={() => handleConfirmationOpen(index)}
                            >
                              <CloseSharpIcon />
                            </IconButton>
                          </CardHeader>
                          <Link to={`./add/${member.familyMemberId}`}>
                            <CardBody>
                              <div className="picture-container">
                                <div className="picture">
                                  <img
                                    src={imagePreviewUrl}
                                    className="picture-src"
                                    alt="..."
                                  />
                                </div>
                              </div>
                              <CardTitle tag="h4"></CardTitle>
                            </CardBody>
                          </Link>
                          <CardHeader className="money-manage-text">
                            <h6 className="card-category">
                              {member.firstName} {member.lastName}
                            </h6>
                          </CardHeader>

                          <CardFooter>
                            {/* <div className="btn_group">
                              <Button
                                className="btn-round"
                                color="primary"
                                href="#pablo"
                                onClick={(e) =>
                                  handleClick(e, member.familyMemberId)
                                }
                              >
                                Edit
                              </Button>
                              <DeleteDialog
                                className="family_member_dialog"
                                onDelete={() => handleDelete(member.id)}
                              />
                            </div> */}
                          </CardFooter>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MoneyManagement;
