import {
  useMultistepForm,
  UserDetails,
  UserAddress,
  BirthDetails,
} from "./index";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FamilyMemberService from "../../../services/family-member.service";
import ClientService from "services/client.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";

const INITIAL_DATA = {
  id: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  memberType: "",
  dateOfBirth: "", //setDateForDisplay(new Date()),
  birthCountry: "",
  gender: "",
  maritalStatus: "",
  address: "",
  housingSituation: "",
  dateMoved: "",
  onLoan: "1",
  onTitle: "0",
  financialDependent: "1",
  untilWhatAge: "65",
  clientId: 0,
  userId: 0,
};

const FamilyMembersAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [open, setOpen] = useState(false);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [isPrimaryMember, setIsPrimaryMember] = useState(false);
  // const [clientId, setClientId] = useState(0);
  // const [userId, setUserId] = useState(0);

  const [
    validationErrorsFamilyMemberDetails,
    setValidationErrorsFamilyMemberDetails,
  ] = useState({});

  function validateFormFamilyMemberDetails() {
    const errorsFamilyMemberDetails = {};
    if (!formData.phone) errorsFamilyMemberDetails.phone = "* "; //"Phone is required";
    if (!formData.email) errorsFamilyMemberDetails.email = "* "; //"Email is required";
    setValidationErrorsFamilyMemberDetails(errorsFamilyMemberDetails);
    return Object.keys(errorsFamilyMemberDetails).length === 0;
  }

  const history = useHistory();

  const getByFamilyMemberId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };

  console.log(
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length
  );

  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length === 5
      ? true
      : false;

  console.log("isUpdate", isUpdate);
  const stepLabels = [
    "Personal Information",
    "Birth Details",
    "Address Details",
  ];
  const steps = [
    <UserDetails
      formData={formData}
      setFormData={setFormData}
      validationErrorsFamilyMemberDetails={validationErrorsFamilyMemberDetails}
      isPrimaryMember={isPrimaryMember}
    />,
    <BirthDetails formData={formData} setFormData={setFormData} />,
    <UserAddress formData={formData} setFormData={setFormData} />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const countries = [
    { label: "Afghanistan", value: "AF" },
    { label: "Åland Islands", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "AndorrA", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, The Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Cote D'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, Democratic People'S Republic of", value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People'S Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "Netherlands Antilles", value: "AN" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestinian Territory, Occupied", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "RWANDA", value: "RW" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia and Montenegro", value: "CS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
  ];

  const setSelectedCountry = (name) => {
    return countries.find((c) => c.label === name);
  };

  const setDateForDisplay = (selectedDate) => {
    return selectedDate.slice(0, 10).split("-").reverse().join("/");
  };

  const setDateForInsert = (selectedDate) => {
    return selectedDate.split("/").reverse().join("-");
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/family-members/list";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getFamilyMember = () => {
    const familyMemberId = getByFamilyMemberId();
    FamilyMemberService.getByFamilyMemberId(familyMemberId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const member = response.data.recordset[0];
          console.log("member: ", member);
          const familyMemberData = {
            id: member.FamilyMemberId,
            familyMemberId: member.FamilyMemberId,
            firstName: member.FirstName,
            middleName: member.MiddleName,
            lastName: member.LastName,
            phone: member.Phone,
            email: member.Email,
            memberType: member.MemberType,
            dateOfBirth: setDateForDisplay(member.DateOfBirth),
            birthCountry: setSelectedCountry(member.CountryOfBirth),
            gender: member.Gender,
            maritalStatus: member.MaritalStatus,
            address: member.Address,
            housingSituation: member.HousingSituation,
            dateMoved: setDateForDisplay(member.DateMoved),
          };
          setFormData(familyMemberData);
        } else {
          setFormData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData([]);
      });
  };

  const getPrimaryFamilyMember = () => {
    FamilyMemberService.getAllByClientId(clientState.clientId)
      .then((response) => {
        if (response.data.recordset.length === 0) {
          // first family member and get details from clients
          ClientService.getByClientId(clientState.clientId)
            .then((response) => {
              if (response.data.recordset.length > 0) {
                setIsPrimaryMember(true);
                const primaryfamilyMemberData = {
                  firstName: response.data.recordset[0].ClientFirstName,
                  lastName: response.data.recordset[0].ClientLastName,
                  phone: response.data.recordset[0].MobileNumber,
                  email: response.data.recordset[0].ClientEmail,
                  memberType: "Primary",
                };
                setFormData({ ...formData, ...primaryfamilyMemberData });
              } else {
                setFormData({ ...INITIAL_DATA });
              }
            })
            .catch((error) => {
              console.log(error);
              setFormData({ ...INITIAL_DATA });
            });
        } else {
          const familyMemberData = {
            memberType: "Secondary",
          };
          setFormData({ ...formData, ...familyMemberData });
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData({ ...INITIAL_DATA });
      });
  };

  useEffect(() => {
    if (isUpdate) {
      getFamilyMember();
    } else {
      getPrimaryFamilyMember();
    }
    console.log("Form Data ", formData);
  }, []);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  const updateFamilyMemberData = () => {
    return {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      phone: formData.phone,
      email: formData.email,
      memberType: formData.memberType,
      gender: formData.gender,
      maritalStatus: formData.maritalStatus,
      address: formData.address,
      housingSituation: formData.housingSituation,
      birthCountry: formData.birthCountry.label,
      dateOfBirth: setDateForInsert(formData.dateOfBirth),
      dateMoved: setDateForInsert(formData.dateMoved),
      onLoan: "1",
      onTitle: "0",
      financialDependent: "1",
      untilWhatAge: "65",
      clientId: clientState.clientId,
      userId: userState.userId,
    };
  };

  const handleNext = () => {
    // if (activeStep === stepLabels.length - 1) {
    const familyMemberId = formData.id;
    const familyMemberData = updateFamilyMemberData();
    console.log("family member data: ", familyMemberData);

    if (activeStep === stepLabels.length - 1) {
      if (!validateFormFamilyMemberDetails()) {
        setActiveStep(0);
        return;
      }

      if (familyMemberData === undefined || familyMemberId === 0) {
        console.log("family member Id: ", familyMemberId);
        FamilyMemberService.add(familyMemberData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        FamilyMemberService.update(familyMemberId, familyMemberData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToList();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (activeStep === 0) {
      // Property Detail
      if (!validateFormFamilyMemberDetails()) {
        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const familyMemberId = formData.id;
    const familyMemberData = updateFamilyMemberData();

    if (!validateFormFamilyMemberDetails()) {
      setActiveStep(0);
      return;
    }

    console.log("family member data update: ", familyMemberData);
    FamilyMemberService.update(familyMemberId, familyMemberData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content family-members">
      <Container>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Family member is saved."
          action={action}
        ></Snackbar>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <Box
                className="layout-footer"
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
              >
                <div className="layout-footer-back">
                  <Button
                    // color="inherit"
                    variant="contained"
                    color="grey"
                    size="medium"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
                {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                <div className="layout-footer-actions">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    onClick={handleNext}
                  >
                    {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    className={`${
                      isUpdate && activeStep !== stepLabels.length - 1
                        ? "hidden-finish-btn"
                        : "show-finish-btn"
                    }`}
                    onClick={handleFinish}
                    variant="contained"
                    color="grey"
                    size="medium"
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button>
                </div>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default FamilyMembersAdd;
