import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";

import assumptionService from "../../../services/assumption.service";
import Button from "@mui/material/Button";
import { useAtom } from "jotai";
import { userAtom, selectedClientAtom } from "../../../store";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";
import { currencyToNumber } from "utils/currency";
import Select from "react-select";
import Spinner from "utils/spinner";

const Assumptions = () => {
  const [defaultAssumption, setDefaultAssumption] = useState({});
  const [state, setState] = useAtom(userAtom);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [open, setOpen] = useState(false);
  const [selectPropertyType, setSelectPropertyType] = useState([]);
  const [selectLoanType, setSelectLoanType] = useState([]);
  const [selectLoanSplit, setSelectLoanSplit] = useState([]);
  const [selectExpenseType, setSelectExpenseType] = useState([]);
  const [selectExpenseFrequency, setSelectExpenseFrequency] = useState([]);
  const [propertyType, setPropertyType] = useState({});
  const [loanType, setLoanType] = useState({});
  const [splitLoan, setLoanSplit] = useState({});
  const [expenseType, setExpenseType] = useState({});
  const [expenseFrequency, setExpenseFrequency] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);

  const history = useHistory();

  useEffect(() => {
    getDefaultAssumptions();
    getPropertyType();
    getLoanType();
    getLoanSplit();
    getExpenseType();
    getExpenseFrequency();
  }, []);

  const getPropertyType = () => {
    setSelectPropertyType([
      {
        label: "Principal place of residence",
        value: "Principal place of residence",
      },
      { label: "Residential", value: "Residential" },
      {
        label: "Commercial",
        value: "Commercial",
      },
    ]);
  };

  const getLoanType = () => {
    setSelectLoanType([
      {
        label: "Principal & interest",
        value: "Principal & interest",
      },
      { label: "Interest only", value: "Interest only" },
    ]);
  };

  const getLoanSplit = () => {
    setSelectLoanSplit([
      {
        label: "No split loan",
        value: 0,
      },
      { label: "Split Loan", value: 1 },
    ]);
  };

  const getExpenseType = () => {
    setSelectExpenseType([
      {
        label: "Summarise",
        value: "Summarise",
      },
      { label: "Breakdown", value: "Breakdown" },
    ]);
  };

  const getExpenseFrequency = () => {
    setSelectExpenseFrequency([
      { label: "Weekly", value: "Weekly" },
      { label: "Fortnightly", value: "Fortnightly" },
      { label: "Monthly", value: "Monthly" },
      { label: "Quarterly", value: "Quarterly" },
      { label: "Annually", value: "Annually" },
    ]);
  };

  const setSelectedPropertyType = (selectedPropertyType) => {
    setPropertyType(selectedPropertyType);
  };

  const setSelectedLoanType = (selectedLoanType) => {
    setLoanType(selectedLoanType);
  };

  const setSelectedLoanSplit = (selectedLoanSplit) => {
    console.log("Loan Split: ", selectedLoanSplit);
    setLoanSplit(selectedLoanSplit);
  };

  const setSelectedExpenseType = (selectedExpenseType) => {
    console.log("Expense type: ", selectedExpenseType);
    setExpenseType(selectedExpenseType);
  };

  const setSelectedExpenseFrequency = (selectedExpenseFrequency) => {
    setExpenseFrequency(selectedExpenseFrequency);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const redirectToCompass = () => {
    const path = "/admin/compass/compass";
    history.push(path);
  };

  const getDefaultAssumptions = async () => {
    assumptionService
      .getDefaultAssumption()
      .then((response) => {
        let defaultAssumptions = response.data.recordset[0];
        console.log("defaultAssumptions: ", defaultAssumptions);
        setDefaultAssumption({
          ...defaultAssumptions,
          SuperContributionCapPerPerson: formatCurrency(
            defaultAssumptions.SuperContributionCapPerPerson
          ),
          DefaultPassiveIncome: formatCurrency(
            defaultAssumptions.DefaultPassiveIncome
          ),
          DefaultNetWorthAtRetirement: formatCurrency(
            defaultAssumptions.DefaultNetWorthAtRetirement
          ),
          DefaultMonthlyAvgSavings: formatCurrency(
            defaultAssumptions.DefaultMonthlyAvgSavings
          ),
          DefaultMonthlyExpenses: formatCurrency(
            defaultAssumptions.DefaultMonthlyExpenses
          ),
          DefaultMonthlySpending: formatCurrency(
            defaultAssumptions.DefaultMonthlySpending
          ),
          DefaultGrossAnnualIncome: formatCurrency(
            defaultAssumptions.DefaultGrossAnnualIncome
          ),
          DefaultTotalSavingsInLiquid: formatCurrency(
            defaultAssumptions.DefaultTotalSavingsInLiquid
          ),
          DefaultSuperOrSMSFValue: formatCurrency(
            defaultAssumptions.DefaultSuperOrSMSFValue
          ),
          PropertyType: {
            label: defaultAssumptions.PropertyType,
            value: defaultAssumptions.PropertyType,
          },
          LoanType: {
            label: defaultAssumptions.LoanType,
            value: defaultAssumptions.LoanType,
          },
          SplitLoan: {
            label: defaultAssumptions.SplitLoan
              ? "Split Loan"
              : "No split loan",
            value: defaultAssumptions.SplitLoan ? "1" : "0",
          },
          PropertyExpensesCalculationMethod: {
            label: defaultAssumptions.PropertyExpensesCalculationMethod,
            value: defaultAssumptions.PropertyExpensesCalculationMethod,
          },
          TotalExpensesFrequency: {
            label: defaultAssumptions.TotalExpensesFrequency,
            value: defaultAssumptions.TotalExpensesFrequency,
          },
          NextCarCashContribution: formatCurrency(
            defaultAssumptions.NextCarCashContribution
          ),
          NextHolidaySpending: formatCurrency(
            defaultAssumptions.NextHolidaySpending
          ),
          EducationCycleSpending: formatCurrency(
            defaultAssumptions.EducationCycleSpending
          ),
          SecondEducationCycleSpending: formatCurrency(
            defaultAssumptions.SecondEducationCycleSpending
          ),
          OtherExpenseSpending: formatCurrency(
            defaultAssumptions.OtherExpenseSpending
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setFamilyMembers([]);
      });
    setShowSpinner(false);
  };

  const updateDefaultAssumption = async () => {
    console.log(
      "defaultAssumption.SplitLoan.value",
      defaultAssumption.SplitLoan.value
    );
    const defaultAssumptionData = {
      assumptionId: defaultAssumption.AssumptionId,
      assumptionName: defaultAssumption.AssumptionName,
      assumptionTypeId: 1,
      userId: state.userId,
      growthRate: defaultAssumption.GrowthRate,
      rentalYield: defaultAssumption.RentalYield,
      rentalIncreasePA: defaultAssumption.RentalIncreasePA,
      occupancyRate: defaultAssumption.OccupancyRate,
      propertyManagement: defaultAssumption.PropertyManagement,
      interestRate: defaultAssumption.InterestRate,
      inflation: defaultAssumption.Inflation,
      newLoanAmount: defaultAssumption.NewLoanAmount,
      acquisitionCost: defaultAssumption.AcquisitionCost,
      baExpenses: defaultAssumption.BAExpenses,
      renovationReturn: defaultAssumption.RenovationReturn,
      borrowingCapacity: defaultAssumption.BorrowingCapacity,
      wageGrowth: defaultAssumption.WageGrowth,
      offsetSavings: defaultAssumption.OffsetSavings,
      newPurchaseRefinance: defaultAssumption.NewPurchaseRefinance,
      sellingCost: defaultAssumption.SellingCost,
      cgtRate: defaultAssumption.CGTRate,
      cgtDiscount: defaultAssumption.CGTDiscount,
      costOfHandling: defaultAssumption.CostOfHandling,
      costOfHandlingIncrease: defaultAssumption.CostOfHandlingIncrease,
      commercialGrowthRate: defaultAssumption.CommercialGrowthRate,
      commercialNetRentalYield: defaultAssumption.CommercialNetRentalYield,
      commercialInterestRate: defaultAssumption.CommercialInterestRate,
      commercialNewLoanAmount: defaultAssumption.CommercialNewLoanAmount,
      commercialPropertyManagement:
        defaultAssumption.CommercialPropertyManagement,
      clientPropertyId: defaultAssumption.ClientPropertyId,
      clientId: clientstate.ClientId,
      savingsPlacedInOffset: defaultAssumption.SavingsPlacedInOffset,
      reFinanceOnNewPurchase: defaultAssumption.ReFinanceOnNewPurchase,
      payLMIOnFuturePurchases: defaultAssumption.PayLMIOnFuturePurchases,
      superContributionCapPerPerson: currencyToNumber(
        defaultAssumption.SuperContributionCapPerPerson
      ),
      superSavingsPoolGrowthRate: defaultAssumption.SuperSavingsPoolGrowthRate,
      superInterestRate: defaultAssumption.SuperInterestRate,
      superTransferAge: defaultAssumption.SuperTransferAge,
      defaultRetirementAge: defaultAssumption.DefaultRetirementAge,
      defaultPassiveIncome: currencyToNumber(
        defaultAssumption.DefaultPassiveIncome
      ),
      defaultNetWorthAtRetirement: currencyToNumber(
        defaultAssumption.DefaultNetWorthAtRetirement
      ),
      defaultMonthlyAvgSavings: currencyToNumber(
        defaultAssumption.DefaultMonthlyAvgSavings
      ),
      defaultMonthlyExpenses: currencyToNumber(
        defaultAssumption.DefaultMonthlyExpenses
      ),
      defaultMonthlySpending: currencyToNumber(
        defaultAssumption.DefaultMonthlySpending
      ),
      defaultGrossAnnualIncome: currencyToNumber(
        defaultAssumption.DefaultGrossAnnualIncome
      ),
      defaultTotalSavingsInLiquid: currencyToNumber(
        defaultAssumption.DefaultTotalSavingsInLiquid
      ),
      defaultSuperOrSMSFValue: currencyToNumber(
        defaultAssumption.DefaultSuperOrSMSFValue
      ),
      defaultIncomeTaxRate: defaultAssumption.DefaultIncomeTaxRate,
      propertyType: defaultAssumption.PropertyType.value,
      dateSettled: defaultAssumption.DateSettled,
      mostRecentLoanCommencedYear:
        defaultAssumption.MostRecentLoanCommencedYear,
      originalLoanTerm: defaultAssumption.OriginalLoanTerm,
      loanType: defaultAssumption.LoanType.value,
      splitLoan: defaultAssumption.SplitLoan.value, // ? 1 : 0,
      propertyExpensesCalculationMethod:
        defaultAssumption.PropertyExpensesCalculationMethod.value,
      totalExpensesFrequency: defaultAssumption.TotalExpensesFrequency.value,

      nextCarPurchaseYear: defaultAssumption.NextCarPurchaseYear,
      nextCarPurchaseFrequency: defaultAssumption.NextCarPurchaseFrequency,
      NextCarCashContribution: currencyToNumber(
        defaultAssumption.NextCarCashContribution
      ),

      nextHolidayYear: defaultAssumption.NextHolidayYear,
      nextHolidayFrequency: defaultAssumption.NextHolidayFrequency,
      nextHolidaySpending: currencyToNumber(
        defaultAssumption.NextHolidaySpending
      ),

      educationFeeFrequency: defaultAssumption.EducationFeeFrequency,
      nextEducationExpenseYear: defaultAssumption.NextEducationExpenseYear,
      yearEducationExpenseStops: defaultAssumption.YearEducationExpenseStops,
      educationCycleSpending: currencyToNumber(
        defaultAssumption.EducationCycleSpending
      ),

      secondEducationFeeFrequency:
        defaultAssumption.SecondEducationFeeFrequency,
      secondEducationExpenseYear: defaultAssumption.SecondEducationExpenseYear,
      yearSecondEducationExpenseStops:
        defaultAssumption.YearSecondEducationExpenseStops,
      secondEducationCycleSpending: currencyToNumber(
        defaultAssumption.SecondEducationCycleSpending
      ),

      otherExpenseFrequency: defaultAssumption.OtherExpenseFrequency,
      otherExpenseYear: defaultAssumption.OtherExpenseYear,
      yearOtherExpenseStops: defaultAssumption.YearOtherExpenseStops,
      otherExpenseSpending: currencyToNumber(
        defaultAssumption.OtherExpenseSpending
      ),
      safetyBufferMonths: defaultAssumption.SafetyBufferMonths,
    };
    console.log("update client assumption: ", defaultAssumptionData);
    assumptionService
      .updateDefaultAssumption(defaultAssumptionData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToCompass();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    updateDefaultAssumption();
  };

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="content assumption">
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Assumption is saved."
        action={action}
      ></Snackbar>

      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Default Assumptions</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row>
                    <Col md="4">
                      <Label>Growth Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.GrowthRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              GrowthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Interest Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.InterestRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              InterestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Borrowing capacity</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Borrowing capacity"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.BorrowingCapacity}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              BorrowingCapacity: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Savings placed in offset</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Savings placed in offset	"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.SavingsPlacedInOffset}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SavingsPlacedInOffset: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" sx={{ paddingTop: "8px" }}>
                      <Label>Re-Finance on New Purchase</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Re-Finance on New Purchase"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.ReFinanceOnNewPurchase}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              ReFinanceOnNewPurchase: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Inflation</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Inflation"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.Inflation}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              Inflation: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Occupancy Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Occupancy Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.OccupancyRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              OccupancyRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Rental Yield</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Rental Yield"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.RentalYield}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              RentalYield: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Label>Rental Increase PA</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Rental Increase PA"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.RentalIncreasePA}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              RentalIncreasePA: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Property Management</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Property Management"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.PropertyManagement}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              PropertyManagement: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Acquisition Costs</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Acquisition Costs"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.AcquisitionCost}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              AcquisitionCost: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Pay LMI on future Purchases</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Pay LMI on future Purchases"
                          type="checkbox"
                          style={{
                            marginLeft: 5,
                          }}
                          // min="0"
                          // oninput="validity.valid||(value='');"
                          checked={
                            defaultAssumption.PayLMIOnFuturePurchases
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              PayLMIOnFuturePurchases: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Renovation Return</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Renovation Return"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.RenovationReturn}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              RenovationReturn: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>BA Expenses</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="BA Expenses"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.BAExpenses}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              BAExpenses: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Cost of Holding</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Cost of Holding"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CostOfHandling}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CostOfHandling: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>New Loan Amount</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="New Loan Amount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.NewLoanAmount}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              NewLoanAmount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Cost of holding Increase</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Cost of holding Increase"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CostOfHandlingIncrease}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CostOfHandlingIncrease: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Wage Growth</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Wage Growth"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.WageGrowth}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              WageGrowth: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Selling Costs</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Selling Costs"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.SellingCost}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SellingCost: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>CGT Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="CGT Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CGTRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CGTRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>CGT Discount</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="CGT Discount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CGTDiscount}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CGTDiscount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Commercial Growth Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Commercial Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CommercialGrowthRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CommercialGrowthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Commercial Net Rental Yield</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Commercial Net Rental Yield"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CommercialNetRentalYield}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CommercialNetRentalYield: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Commercial Interest Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Commercial Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CommercialInterestRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CommercialInterestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Commercial New Loan Amount</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Commercial New Loan Amount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CommercialNewLoanAmount}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CommercialNewLoanAmount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Commercial Property Management</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Commercial Property Management"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.CommercialPropertyManagement}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              CommercialPropertyManagement: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Super Contribution Cap Per-Person</Label>
                      <FormGroup>
                        <CurrencyInput
                          disabled={state.isClient}
                          placeholder="Super Contribution Cap Per-Person"
                          number={
                            defaultAssumption.SuperContributionCapPerPerson
                          }
                          handleNumberChange={(value) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SuperContributionCapPerPerson: value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Super Savings Pool Growth Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Super Savings Pool Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.SuperSavingsPoolGrowthRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SuperSavingsPoolGrowthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Super Interest Rate</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Super Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.SuperInterestRate}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SuperInterestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {" "}
                      <Label>Super Transfer Age</Label>
                      <FormGroup>
                        <Input
                          disabled={state.isClient}
                          placeholder="Super Transfer Age"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={defaultAssumption.SuperTransferAge}
                          onChange={(e) =>
                            setDefaultAssumption({
                              ...defaultAssumption,
                              SuperTransferAge: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    {state.isAdmin && (
                      <>
                        <Col md="4">
                          {" "}
                          <Label>Default Retirement Age</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Default Retirement Age"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.DefaultRetirementAge}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultRetirementAge: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Default Passive Income</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Passive Income"
                              number={defaultAssumption.DefaultPassiveIncome}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultPassiveIncome: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Default Net Worth</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Net Worth"
                              number={
                                defaultAssumption.DefaultNetWorthAtRetirement
                              }
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultNetWorthAtRetirement: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Monthly Avg. Savings</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Monthly Avg Savings"
                              number={
                                defaultAssumption.DefaultMonthlyAvgSavings
                              }
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultMonthlyAvgSavings: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Monthly Expenses</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Monthly Expenses"
                              number={defaultAssumption.DefaultMonthlyExpenses}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultMonthlyExpenses: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Monthly Spending</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Monthly Spending"
                              number={defaultAssumption.DefaultMonthlySpending}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultMonthlySpending: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Gross Annual Income</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Gross Annual Income"
                              number={
                                defaultAssumption.DefaultGrossAnnualIncome
                              }
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultGrossAnnualIncome: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Income Tax Rate</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Default Income Tax Rate"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={parseFloat(
                                defaultAssumption.DefaultIncomeTaxRate
                              )}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultIncomeTaxRate: parseFloat(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Total Savings In Liquid</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Default Income Tax Rate"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={parseFloat(
                                defaultAssumption.DefaultIncomeTaxRate
                              )}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultIncomeTaxRate: parseFloat(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Default Super Or SMSF Value</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Default Super Or SMSF Value"
                              number={defaultAssumption.DefaultSuperOrSMSFValue}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultSuperOrSMSFValue: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Allocate Income to Super or SMSF (%)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Default Allocate Income to Super or SMSF (%)"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={parseFloat(
                                defaultAssumption.DefaultAllocateIncomeToSuper
                              )}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DefaultAllocateIncomeToSuper: parseFloat(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Property Type</Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control"
                              placeholder="Select property type"
                              classNamePrefix="react-select"
                              options={selectPropertyType}
                              value={defaultAssumption.PropertyType}
                              onChange={(propertyType) => {
                                setSelectedPropertyType(propertyType);
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  PropertyType: propertyType,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>
                            Date Settled (Year(s) Prior to Current Year)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.DateSettled}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  DateSettled: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>
                            Recent Loan Year (Year(s) Prior to Current Year)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={
                                defaultAssumption.MostRecentLoanCommencedYear
                              }
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  MostRecentLoanCommencedYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Default Loan Term</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.OriginalLoanTerm}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  OriginalLoanTerm: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Default Loan Type</Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control"
                              placeholder="Select Loan type"
                              classNamePrefix="react-select"
                              options={selectLoanType}
                              value={defaultAssumption.LoanType}
                              onChange={(loanType) => {
                                setSelectedLoanType(loanType);
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  LoanType: loanType,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Default Loan Split</Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control"
                              placeholder="Select Loan Split"
                              classNamePrefix="react-select"
                              options={selectLoanSplit}
                              value={defaultAssumption.SplitLoan}
                              onChange={(splitLoan) => {
                                setSelectedLoanSplit(splitLoan);
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  SplitLoan: splitLoan,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Safety Buffer (Months)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Months"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.SafetyBufferMonths}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  SafetyBufferMonths: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Expense Calculation Method</Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control"
                              placeholder="Select Expense Type"
                              classNamePrefix="react-select"
                              options={selectExpenseType}
                              value={
                                defaultAssumption.PropertyExpensesCalculationMethod
                              }
                              onChange={(expenseType) => {
                                setSelectedExpenseType(expenseType);
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  PropertyExpensesCalculationMethod:
                                    expenseType,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Total Expense Frequency</Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control"
                              placeholder="Select Expense Frequency"
                              classNamePrefix="react-select"
                              options={selectExpenseFrequency}
                              value={defaultAssumption.TotalExpensesFrequency}
                              onChange={(expenseFrequency) => {
                                setSelectedExpenseFrequency(expenseFrequency);
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  TotalExpensesFrequency: expenseFrequency,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Next Vehicle Purchase within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.NextCarPurchaseYear}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextCarPurchaseYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>
                            How often you purchase new vehicle (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.NextCarPurchaseFrequency}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextCarPurchaseFrequency: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Cash contribution for next car</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Cash Contribution"
                              number={defaultAssumption.NextCarCashContribution}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextCarCashContribution: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Next holiday within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.NextHolidayYear}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextHolidayYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>How often you go for holidays (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.NextHolidayFrequency}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextHolidayFrequency: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Spending for next holiday</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Cash Contribution"
                              number={defaultAssumption.NextHolidaySpending}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextHolidaySpending: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>
                            How frequently do you pay for education (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.EducationFeeFrequency}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  EducationFeeFrequency: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Next education expense within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.NextEducationExpenseYear}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  NextEducationExpenseYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>
                            Next education expense stops within (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={
                                defaultAssumption.YearEducationExpenseStops
                              }
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  YearEducationExpenseStops: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Spending per education cycle</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Spending"
                              number={defaultAssumption.EducationCycleSpending}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  EducationCycleSpending: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>
                            Frequency of payment for 2nd education (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={
                                defaultAssumption.SecondEducationFeeFrequency
                              }
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  SecondEducationFeeFrequency: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>2nd education expense within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={
                                defaultAssumption.SecondEducationExpenseYear
                              }
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  SecondEducationExpenseYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>
                            2nd education expense stops within (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={
                                defaultAssumption.YearSecondEducationExpenseStops
                              }
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  YearSecondEducationExpenseStops:
                                    e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Spending per 2nd education cycle</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Spending"
                              number={
                                defaultAssumption.SecondEducationCycleSpending
                              }
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  SecondEducationCycleSpending: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>
                            Frequency of payment for other expenses (Years)
                          </Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.OtherExpenseFrequency}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  OtherExpenseFrequency: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Other expense within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.OtherExpenseYear}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  OtherExpenseYear: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Other expense stops within (Years)</Label>
                          <FormGroup>
                            <Input
                              disabled={state.isClient}
                              placeholder="Number of Years"
                              type="number"
                              min="0"
                              oninput="validity.valid||(value='');"
                              value={defaultAssumption.YearOtherExpenseStops}
                              onChange={(e) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  YearOtherExpenseStops: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          {" "}
                          <Label>Other expense spending per cycle</Label>
                          <FormGroup>
                            <CurrencyInput
                              disabled={state.isClient}
                              placeholder="Spending"
                              number={defaultAssumption.OtherExpenseSpending}
                              handleNumberChange={(value) =>
                                setDefaultAssumption({
                                  ...defaultAssumption,
                                  OtherExpenseSpending: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                {state.isAdmin && (
                  <>
                    <Row className="butttton">
                      <Col md="3" />
                      <Col md="9">
                        <Button
                          sx={{ float: "right" }}
                          onClick={handleSave}
                          variant="contained"
                          color="grey"
                          size="medium"
                          disabled={state.isClient}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Assumptions;
